html,body{
  height: 100vh;
}

.gg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Couleur de fond semi-transparente */
  z-index: 1000; /* Assurez-vous que l'overlay est au-dessus de tous les autres éléments */
}
